import LinkedInIcon from "@mui/icons-material/LinkedIn";

export const AboutDescription = [
  "At DAFY Online, we understand that your time is valuable, and our services are designed to cater to your unique needs.",
  "Whether you need a reliable driver for your daily commute, a professional chauffeur for a special occasion, or assistance with transportation logistics, we have you covered.",
  "Our user-friendly platform makes booking our services quick and easy. We are dedicated to providing convenient and reliable assistants to help you manage your busy lifestyle with ease.",
];

export const roadmapData = [
  {
    id: 1,
    logoSrc: require("../../Assets/Team/Manikandan-aboutpage.png"), // Replace with the actual logo file path
    title: "Manikandan Ak",
    social: {
      linkedin: "https://www.linkedin.com",
    },
    info: "Founder/CEO",
    xs: "12",
    sm: "6",
    md: "6",
    lg: "6",
    xl: "2.4",
  },
  {
    id: 2,
    logoSrc: require("../../Assets/Team/Arun-aboutPage.png"), // Replace with the actual logo file path
    title: "Arun Chandrasekaran",
    social: { linkedin: "" },
    info: "Co-Founder/COO",
    icon: "",
    xs: "12",
    sm: "6",
    md: "6",
    lg: "6",
    xl: "2.4",
  },
  {
    id: 3,
    logoSrc: require("../../Assets/Team/stephin-aboutpage.png"), // Replace with the actual logo file path
    title: "Stephin Jose",
    info: "Co-Founder/Accounts Head",
    social: { linkedin: "" },
    xs: "12",
    sm: "6",
    md: "6",
    lg: "6",
    xl: "2.4",
  },
  {
    id: 4,
    logoSrc: require("../../Assets/Team/jijo-aboutpage.png"), // Replace with the actual logo file path
    title: "Jijo Loius",
    social: { linkedin: "" },
    info: "HR Head",
    xs: "12",
    sm: "6",
    md: "6",
    lg: "6",
    xl: "2.4",
  },
  {
    id: 5,
    logoSrc: require("../../Assets/Team/Josen-Aboutpage.png"), // Replace with the actual logo file path
    title: "Josen Kuriakose",
    social: { linkedin: "" },
    info: "Operations Head",
    icon: "",
    xs: "12",
    sm: "12",
    md: "6",
    lg: "6",
    xl: "2.4",
  },
  // Add more elements as needed
];

export const SocialMediaIcons = [
  {
    name: "linkedin",
    icon: <LinkedInIcon fontSize="large" sx={{}}/>,
  },
];
